import { profileFormFields } from "./utils/profileFormFields"
import { generateFormField } from "elements/Form/services/form"

const EditForms = ({ setFieldValue, module, values }) => {
  let formFields = profileFormFields(values, setFieldValue)

  return formFields
    .filter((formField) => {
      return formField.inclusions.includes(module)
    })
    .map((formField) => {
      if (!formField?.referenceAnswer) {
        return generateFormField({
          formField,
          values,
          formFields: formFields,
        })
      }
      return null
    })
}

export default EditForms
