export const profileFormFields = (values, setFieldValue) => {
  return [
    {
      type: "password",
      name: "oldPassword",
      label: "Current Password",
      placeholder: "********",
      inclusions: ["update-password"],
      isFollowUpQuestion: false,
      isRequired: true,
    },
    {
      type: "password",
      name: "newPassword",
      label: "New Password",
      placeholder: "********",
      inclusions: ["update-password"],
      isFollowUpQuestion: false,
      isRequired: true,
    },
    {
      type: "password",
      name: "confirmPassword",
      label: "Confirm Password",
      placeholder: "********",
      inclusions: ["update-password"],
      isFollowUpQuestion: false,
      isRequired: true,
    },
    {
      type: "text",
      name: "firstName",
      label: "First Name",
      placeholder: "Juan",
      inclusions: ["edit"],
      isFollowUpQuestion: false,
      isRequired: true,
    },
    {
      type: "text",
      name: "lastName",
      label: "Last Name",
      placeholder: "Dela Cruz",
      inclusions: ["edit"],
      isFollowUpQuestion: false,
      isRequired: true,
    },
    {
      type: "text",
      name: "email",
      label: "Email",
      placeholder: "jdelacruz@gmail.com",
      inclusions: ["edit"],
      disabled: true,
      isFollowUpQuestion: false,
      isRequired: true,
    },
    {
      type: "text",
      name: "mobileNumber",
      label: "Mobile Number",
      placeholder: "09123456789",
      inclusions: ["edit"],
      isFollowUpQuestion: false,
      isRequired: true,
    },
    {
      type: "date",
      name: "birthday",
      label: "Birthday",
      inclusions: ["edit"],
      values: values?.birthday,
      isRequired: true,
    },
    {
      type: "address",
      fieldNames: {
        addressType: `patientAddress.addressType`,
        streetAddress: `patientAddress.streetAddress`,
        city: `patientAddress.city`,
        province: `patientAddress.province`,
        barangay: `patientAddress.barangay`,
      },
      values: values?.patientAddress,
      setFieldValue: setFieldValue,
      inclusions: ["add-address", "edit-address"],
      isNationwide: true,
      isRequired: true,
    },
  ]
}
